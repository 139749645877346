<template lang="pug">
.user-list
  .d-flex.justify-between.align-center
    .text-gray-900.text-2xl {{ title }} 
    div
      v-btn(
        color="primary"
        v-if="$can('create', 'users')"
        depressed
        @click="isUserCreateEditOpen = true"
      ) + New Member

  .d-flex.justify-between.mt-16.gap-8
    v-text-field(
      class="w-full lg:w-1/2 xl:w-1/3 mb-4"
      v-model="search"
      label="Search"
      prepend-inner-icon="mdi-magnify"
      outlined
      dense
      clearable
    )
    v-select(
      class="w-full lg:w-1/2 xl:w-1/3 mb-4"
      v-model="status"
      outlined
      multiple
      dense
      :items="statuses"
    )

  v-data-table.border.shadow(
    must-sort
    :items="users"
    :loading="isPending"
    :server-items-length="!isPending && pagination ? pagination.total : 0"
    :headers="headers"
    :search.sync="search"
    :options.sync="options"
    no-data-text="No users found"
  )
    template(
      v-slot:item.status="{ item }"
    )
      v-chip( 
        :color="item.statusColor" 
        :text-color="item.textColor"
        small 
      ) 
        span {{ item.status }}
    template(
      v-slot:item.actions="{ item: user }"
    )
      v-menu( left offset-y )
        template( v-slot:activator="{ on, attrs }" )
          v-btn(
            color="gray"
            v-bind="attrs"
            v-on="on"
            depressed
            small
            fab
          )
            v-icon mdi-dots-vertical
        v-list.divide-y
          v-list-item( 
            v-if="user.actions.edit"
            class="hover:bg-gray-200 cursor-pointer"
            @click="openDialog(user, 'adminUser')"
          )
            v-list-item-icon
              v-icon( color="primary" ) mdi-square-edit-outline
            v-list-item-content
              v-list-item-title.cursor-pointer Edit

          v-list-item( 
            v-if="user.actions.deactivate"
            class="hover:bg-gray-200 cursor-pointer"
            @click="openDialog(user, 'confirmDeactivate')"
          )
            v-list-item-icon
              v-icon( color="primary" ) mdi-account-cancel
            v-list-item-content
              v-list-item-title.cursor-pointer Deactivate

          v-list-item( 
            v-if="user.actions.activate"
            class="hover:bg-gray-200 cursor-pointer"
            @click="openDialog(user, 'confirmActivate')"
          )
            v-list-item-icon
              v-icon( color="primary" ) mdi-account-reactivate
            v-list-item-content
              v-list-item-title.cursor-pointer Activate

          v-list-item( 
            v-if="user.actions.resendEmail && user.verifyToken"
            class="hover:bg-gray-200 cursor-pointer"
            @click="resendEmail(user.id)"
          )
            v-list-item-icon
              v-icon( color="primary" ) mdi-email
            v-list-item-content
              v-list-item-title.cursor-pointer Resend Email 

  UserCreateEditDialog(
    :isOpen="isUserCreateEditOpen"
    @close="closeAndRefresh"
    :editingUser="editingUser"
    v-if="isUserCreateEditOpen"
  )        

  ConfirmDialog(
    :title="confirmContext.title"
    :text="confirmContext.text"
    :actionText="confirmContext.actionText"
    :data="confirmContext.data"
    :type="confirmContext.type"
    :isOpen="isConfirmDialogOpen"
    @close="closeAndMaybeUpdateUser"
    v-if="isConfirmDialogOpen"
  )

</template>

<script>
import { ref, reactive, computed } from '@vue/composition-api'
import ConfirmDialog from '@/components/Dialogs/Confirm.Dialog'
import UserCreateEditDialog from '@/views/users/User.CreateEdit.Dialog'
import useDataTableFind from '@/helpers/useDataTableFind'
import dataTableHeaders from '@/helpers/dataTableHeaders'

export default {
  name: 'UserList',

  components: {
    UserCreateEditDialog,
    ConfirmDialog
  },

  methods: {
    async resendEmail(userId) {
      const { AuthManagement, User } = this.$FeathersVuex.api;
      const { email } = await User.get(userId)
      const authManagement = new AuthManagement({
        action: "resendVerifySignup",
        value: { email }
      });

      try {
        await authManagement.create();
      } catch(e) {
        this.$snackError(e)
      }
    },
    
    openDialog(data, dialog) {
      switch (dialog) {
        case 'adminUser': {
          this.isUserCreateEditOpen = true
          this.editingUser = data
          break
        }
        case 'confirmDeactivate': {
          this.confirmContext = {
            title: "Deactivate User",
            text: `Are you sure you want to deactivate ${ data.fullName }?`,
            actionText: "Deactivate",
            type: "warning",
            data: {
              firstName: data.firstName,
              id: data.id,
              status: 'inactive',
              action: 'deactived'
            }
          }
          this.isConfirmDialogOpen = true
          break
        }
        case 'confirmActivate': {
          this.confirmContext = {
            title: "Activate User",
            text: `Are you sure you want to activate ${ data.fullName }?`,
            actionText: "Activate",
            type: "info",
            data: {
              firstName: data.firstName,
              id: data.id,
              status: 'pending',
              action: 'activated'
            }
          }
          this.isConfirmDialogOpen = true
          break
        }
        default: {
          console.error('Invalid method name')
          break;
        }
      }
    },
    closeAndRefresh(refresh) {
      if (refresh) this.findUsers()
      this.isUserCreateEditOpen = false;
      this.editingUser = null;
    },
    async closeAndMaybeUpdateUser({ run, data }) {
      if (run) {
        const { id, status, action, firstName } = data
        const { User } = this.$FeathersVuex.api
        const newUser = new User({ id })
        
        try {
          await newUser.patch({ data: { status } })
          this.$snackSuccess(`Successfully ${ action } ${ firstName }`)
          this.findUsers();
        } catch(e) {
          this.$snackError(e)
        }
      }
      
      this.isConfirmDialogOpen = false
    }
  },

  setup(props, { root: { $store } }) {
    const { group } = $store.getters['auth/user']
    const isCommittee = group === 'committee'
    const title = isCommittee ? 'Committee' : 'Team'
    const editingUser = ref(null)
    const confirmContext = reactive({})
    const isUserCreateEditOpen = ref(false)
    const isConfirmDialogOpen = ref(false)
    
    const status = ref(['new', 'pending', 'active'])
    const statuses = ref([
      {
        value: 'new',
        text: 'New'
      }, 
      {
        value: 'pending',
        text: 'Pending'
      },
      {
        value: 'active',
        text: 'Active'
      }, 
      {
        value: 'inactive',
        text: 'Inactive'
      } 
    ]);
    const { headers, filterableColumns } = dataTableHeaders(
      [
        {
          text: 'FirstName',
          value: 'firstName',
          filterable: true
        },
        {
          text: 'LastName',
          value: 'lastName',
          filterable: true
        },
        {
          text: 'Email',
          value: 'email',
          filterable: true
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Role',
          value: 'role.name'
        }
      ], 
      [],
      true
    );

    const roleId = { $nin: isCommittee ? [3] : [5] };
    const filter = computed(() => {
      return { 
        $eager: '[role]',
        status: { $in: status.value },
        roleId
      }
    });
    const { 
      items: users, 
      isPending, 
      pagination, 
      findItems: findUsers, 
      options, 
      search
    } = useDataTableFind(
      'users',
      filterableColumns,
      filter,
      { sortBy: ['firstName'] }
    )

    return {
      users,
      isPending,
      editingUser,
      headers,
      options,
      pagination,
      confirmContext,
      title,
      findUsers,

      status,
      statuses,
      search,

      isUserCreateEditOpen,
      isConfirmDialogOpen
    }
  }
}
</script>